.wvi,
.wvi > input,
.wvi .ant-checkbox-inner,
.wvi .ant-select-selection,
.wvi .ant-select-search__field,
.wvi > div > input {
  border-color: red !important;
}

.marginTop {
  margin-top: 5px;
}
