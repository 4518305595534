.satellites-indicateur {
  margin-right: 0.8rem;
  margin-left: 0.8rem;
}
.satellite-context-line {
  background-color: white;
}
.satellite-active-context {
  font-weight: bold;
}
.satellite-menu {
  z-index: 100;
}
