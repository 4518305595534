.card-header-draggable {
  cursor: pointer;
}

.header-light {
  opacity: 0;
  transition: opacity 0.2s;
}

.panel-light-wrapper:hover > .header-light {
  opacity: 1;
  transition: opacity 0.2s;
}
