.galaxy-tab-container {
  -webkit-overflow-scrolling: touch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -ms-flexbox;
  display: flex;
  font-size: 1rem;
  -ms-flex-pack: justify;
  justify-content: space-between;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
}

.galaxy-tab-container ul {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
}

.galaxy-tab-container li {
  display: block;
  padding-bottom: 0.2em;
  font-weight: 600;
}

.galaxy-tab-container li div {
  align-items: center;
  border-bottom-color: #dbdbdb;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: #4a4a4a;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: -1px;
  padding: 0.5em 1em;
  vertical-align: top;
}

.galaxy-tab-container li a {
  color: #4a4a4a;
}

.galaxy-tab-container li:hover div {
  border-bottom-color: #000;
}

.galaxy-tab-container li.is-active a {
  color: #3273dc;
}

.galaxy-tab-container li.is-active div {
  border-bottom-color: #3273dc;
}

.galaxy-tab-container li.is-active:hover div {
  border-bottom-color: #3273dc;
}
