.gridWrapper2Cols {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-row-gap: 1px;
}

.gridWrapper3Cols {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-row-gap: 1px;
}
