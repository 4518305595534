.datatable-header:focus,
.datatable-body:focus {
  outline: none;
}

.datatable-body {
  background-color: hsl(0, 0%, 96%);
  border-bottom-left-radius: var(--datatable-radius);
  border-bottom-right-radius: var(--datatable-radius);
  /* border-bottom: 1px solid var(--border-datatable); */
}

.datatable-body::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 80%);
  border-radius: 10px;
}

.datatable-body::-webkit-scrollbar {
  width: 0.8em;
  height: 0.8em;
  background-color: hsl(0, 0%, 98%);
}

.datatable-placeholder-cell {
  display: inline-block;
  height: 1em;
  width: 100%;
  background-color: #ddd;
}

.datatable-header {
  width: 100%;
  overflow: hidden !important;
  background-color: var(--background-head);
}

.datatable-toolbar {
  display: flex;
  height: 60px;
  background-color: var(--background-toolbar);
  border-top-left-radius: var(--datatable-radius);
  border-top-right-radius: var(--datatable-radius);
  /* border-top: 1px solid var(--border-datatable);
  border-left: 1px solid var(--border-datatable);
  border-right: 1px solid var(--border-datatable); */
}

.datatable-even-row {
  background-color: var(--background-row-even);
}
.datatable-odd-row {
  background-color: var(--background-row-odd);
}

.datatable-cell,
.datatable-header-cell {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 0.5em;
}

.datatable-cell-bordered {
  border-right: 1px solid #c5c5c5;
}

.datatable-cell.cell-editor {
  align-items: initial;
  text-align: initial;
  justify-content: flex-start;
}

.datatable-header-cell[data-filter-active="true"] {
  justify-content: space-around;
}

.datatable-row-first-cell[data-state-row="new"] {
  border-left: 2px solid hsl(171, 100%, 41%);
}

.datatable-row-first-cell[data-state-row="modified"] {
  border-left: 2px solid hsl(204, 86%, 53%);
}

.datatable-row-first-cell[data-state-row="modified-and-not-sync"] {
  border-left: 2px solid hsl(348, 100%, 61%);
}

.datatable-row-first-cell[data-state-row="not-sync"] {
  border-left: 2px solid hsl(48, 100%, 67%);
}

.datatable-row:hover > .datatable-cell {
  background-color: hsl(210, 98.8%, 94%);
}
.datatable-cell.background-highlight-row {
  background-color: hsl(209, 95%, 90.1%);
}

.datatable-cell {
  color: var(--highlight-row-font);
  background-color: var(--highlight-row);
}

.datatable-header-cell {
  overflow-x: hidden;
  /* border-right: 1px solid #c5c5c5; */
}

.datatable-header-cell--title {
  font-size: 0.8em;
  white-space: nowrap;
  word-wrap: break-word;
}

.datatable-header-cell--label {
  cursor: pointer;
  margin-right: 2px;
  user-select: none;
}

.datatable-header-cell--resize {
  cursor: w-resize;
  margin-right: 2px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.filter-item {
  display: none;
  animation: fade-in 300ms ease-in-out both;
}

.filter-item.is-active {
  display: block;
  animation: fade-in 300ms ease-in-out both;
}

.sort-container {
  display: inline-flex;
  flex-flow: column;
  position: relative;
  margin-left: 1em;
}

.sort-container a {
  line-height: 1;
  height: 0.5em;
}
.sort-container a i {
  vertical-align: top;
  height: 0.5em;
}

.datatableRowsColorGreen {
  border-bottom-color: #4caf50;
  border-bottom-style: solid;
  border-bottom-width: 3px;
}

.datatableRowsColorBlue {
  border-bottom-color: #2196f3;
  border-bottom-style: solid;
  border-bottom-width: 3px;
}

.datatableRowsColorRed {
  border-bottom-color: #f5493d;
  border-bottom-style: solid;
  border-bottom-width: 3px;
}

.datatableRowsColorOrange {
  border-bottom-color: #ff9800;
  border-bottom-style: solid;
  border-bottom-width: 3px;
}

.datatableRowsColorYellow {
  border-bottom-color: #ffc107;
  border-bottom-style: solid;
  border-bottom-width: 3px;
}
