.commentaire-header {
  margin-bottom: 1rem;
}
.conf-step {
  margin-bottom: 2rem;
}
.message-deale {
  padding-top: 1rem;
}
.donnees-satellite-container {
  margin-left: 3px;
  margin-right: 3px;
}
.tab-message-pre {
  background: none;
  white-space: pre-line !important;
}
.margin-top {
  margin-top: 1rem;
}

.commentaire-editor {
  min-height: 300px;
}

.cursor-force-default {
  cursor: default;
}

.commentaire-entete-table {
  height: 35vh;
  margin-bottom: 1rem;
}

.commentaire-source {
  margin-left: 1px;
}

.satellite-menu-item {
  display: flex;
  justify-content: space-between;
}
