@keyframes bouncing-loader {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0.1;
    transform: translateY(-1rem);
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}
.bouncing-loader > div {
  width: 1rem;
  height: 1rem;
  margin: 3rem 0.2rem;
  background: #8385aa;
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
}
.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}
.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #8385aa;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.svg-galaxie-loading-cls-1,
.svg-galaxie-loading-cls-9 {
  fill: #e8f4fa;
}
.svg-galaxie-loading-cls-2 {
  fill: #fff;
}
.svg-galaxie-loading-cls-13,
.svg-galaxie-loading-cls-3 {
  fill: none;
}
.svg-galaxie-loading-cls-10,
.svg-galaxie-loading-cls-11,
.svg-galaxie-loading-cls-13,
.svg-galaxie-loading-cls-3,
.svg-galaxie-loading-cls-9 {
  stroke: #45413c;
  stroke-linejoin: round;
}
.svg-galaxie-loading-cls-3,
.svg-galaxie-loading-cls-9 {
  stroke-linecap: round;
}
.svg-galaxie-loading-cls-4 {
  fill: #020202;
  opacity: 0.15;
}
.svg-galaxie-loading-cls-5 {
  fill: #00b8f0;
}
.svg-galaxie-loading-cls-6 {
  fill: #4acfff;
}
.svg-galaxie-loading-cls-7 {
  fill: #daedf7;
}
.svg-galaxie-loading-cls-8 {
  fill: #c0dceb;
}
.svg-galaxie-loading-cls-10 {
  fill: #45413c;
}
.svg-galaxie-loading-cls-11,
.svg-galaxie-loading-cls-14 {
  fill: #6dd627;
}
.svg-galaxie-loading-cls-12 {
  fill: #9ceb60;
}
