.is-mega {
  position: static;
}

.is-mega.is-mega-menu-title {
  margin-bottom: 0;
  padding: 0.375rem 1rem;
}

.menubox {
  box-shadow: rgba(22, 29, 31, 0.25) 0px 4px 8px !important;
}
