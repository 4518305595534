.autocomplete-list-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}

.autocomplete-list-container[hidden] {
  display: none;
}

.autocomplete-list-item-parent {
  padding: calc(50px / 4);
  border-bottom: 1px solid #eee;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.autocomplete-list-item {
  flex-wrap: wrap;
  margin: 0;
  color: #363636;
  white-space: pre-line;
}

[data-reach-combobox-option] {
  border-left: 3px solid transparent;
}

[data-reach-combobox-option][aria-selected="true"] {
  background: hsl(211, 10%, 95%);
  border-left: 3px solid hsl(204, 86%, 53%);
}

[data-reach-combobox-option]:hover {
  background: hsl(211, 10%, 92%);
  border-left: 3px solid hsl(204, 86%, 53%);
}

[data-reach-combobox-option][aria-selected="true"]:hover {
  background: hsl(211, 10%, 90%);
  border-left: 3px solid hsl(217, 71%, 53%);
}
