.dot-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.dot {
  width: 50%;
  height: 50%;
  max-width: 50%;
  max-height: 50%;
  border-radius: 50%;
}
