@import "./style/octalStyle.css";

/* ::selection {
  background: #ffb7b7;
  background: rgba(255, 245, 30, 0.9);
} */

:root {
  --border-datatable: hsl(0, 0%, 78%);
  --background-head: hsl(0, 0%, 93%);
  --background-toolbar: hsl(0, 0%, 96%);
  --background-row-odd: hsl(0, 0%, 95.1%);
  --background-row-even: hsl(0, 0%, 100%);
  --datatable-radius: 5px;
  /* --fa-secondary-color: hsla(205, 40%, 38%, 1); */
  /* --fa-primary-color: hsl(217, 71%, 53%); */
  --fa-secondary-opacity: 0.7;
  --fa-primary-opacity: 1;
}

.has-no-hover-background:hover {
  background: none !important;
}

.disabled-link {
  color: lightgrey;
  pointer-events: none;
  cursor: default;
}

[data-required="true"]:after {
  content: "*";
  margin-left: 0.2em;
  color: red;
}

[data-right-align="true"],
.input[type="number"] {
  text-align: right;
}

.switch[type="checkbox"]:focus + label::before {
  border-color: #3273dc;
  box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
}

.input[pattern]:invalid,
.input[type="email"]:invalid {
  border: 1px solid hsl(348, 86%, 61%);
}

input[type="date"]:not([data-initial-appearance="true"])::-webkit-inner-spin-button,
input[type="date"]:not([data-initial-appearance="true"])::-webkit-calendar-picker-indicator,
input[type="date"]:not([data-initial-appearance="true"])::-webkit-clear-button {
  display: none;
}

input[type="date"]:hover::-webkit-inner-spin-button,
input[type="date"]:focus::-webkit-inner-spin-button,
input[type="date"]:hover::-webkit-calendar-picker-indicator,
input[type="date"]:focus::-webkit-calendar-picker-indicator,
input[type="date"]:hover::-webkit-clear-button,
input[type="date"]:focus::-webkit-clear-button {
  display: initial;
}

input[type="number"]:disabled::-webkit-inner-spin-button,
input[type="number"]:disabled::-webkit-outer-spin-button,
input[type="number"]:read-only::-webkit-inner-spin-button,
input[type="number"]:read-only::-webkit-outer-spin-button {
  display: none;
}

.no-underline {
  text-decoration: none !important;
}

.underline-link {
  color: hsl(0, 0%, 14%);
  text-decoration: underline;
  text-decoration-color: #3273dc;
}
.underline-link:hover,
.underline-link:focus {
  color: hsl(0, 0%, 29%);
}

.link {
  color: #363636;
}
.link:hover,
.link:focus {
  text-decoration: underline;
  text-decoration-color: #3273dc;
}

.underline-link[data-variant="purple"] {
  text-decoration-color: rgb(108, 99, 255);
}

.background-link {
  color: #3273dc;
  padding: 0 10px;
  border-radius: 99999px;
  transition: color 0.2s;
}

.background-link:hover,
.background-link:focus {
  background-color: #3273dc;
  color: white;
  transition: color 0.2s;
}

.underline {
  text-decoration: underline;
  color: hsl(0, 0%, 29%);
}

.cursor-pointer {
  cursor: pointer;
}

.clear-icon-input {
  pointer-events: initial !important;
  cursor: pointer !important;
}

.is-fullwidth {
  width: 100%;
}

td.text-right,
th.text-right {
  text-align: right !important;
}

td.text-center,
th.text-center {
  text-align: center !important;
}

td.text-left,
th.text-left {
  text-align: left !important;
}

html.modal-open,
body.modal-open {
  overflow: hidden !important;
}

body.modal-open {
  height: 100%;
}

.word-break-none {
  word-break: keep-all;
}
.word-break {
  word-break: break-word;
}

.word-break-all {
  word-break: break-all;
}

.highlight-drop {
  border: 2px solid transparent;
  border-radius: 5px;
  transition: all 0.2s ease-in;
}

.highlight-drop.is-hover {
  background-color: hsla(225, 9%, 30%, 1);
  box-shadow: inset hsla(225, 9%, 33%, 1) 0px 0px 5px 1px;
  transition: all 0.2s ease-in;
}

.highlight-drop.is-new {
  padding-bottom: 40px;
}

.highlight-drop-delete {
  transition: all 0.2s ease-in;
}

.transition-all {
  transition: all 500ms;
}

.box-link {
  cursor: pointer;
}

.box-link:hover,
.box-link:focus,
.box-link.is-active {
  box-shadow: 0 0px 0px 1px hsl(217, 71%, 53%);
  /* border-color: hsl(217, 71%, 53%); */
}
