.app {
  padding: 0;
}

.app .topbar {
  position: fixed;
  right: 0;
  left: 200px;
  height: 60px;
  background-color: #0388e5;
  padding: 0.4em;
  box-sizing: border-box;
  color: #ffffff;
  z-index: 11;
}

.app .topbar .topbar-title {
  display: inline-block;
  padding-top: 0.1em;
}

.app .topbar-menu {
  position: fixed;
  right: 0;
  top: 60px;
  left: 200px;
  min-height: 0.75rem;
  background-color: #efefef;
  z-index: 10;
}

.app .sidebar {
  position: fixed;
  width: 200px;
  height: 100%;
  background-color: hsl(225, 9%, 25%);
  padding: 0.2em;
  z-index: 11;
}

.app .sidebar .logo {
  text-align: center;
  height: 60px;
}

.app .sidebar .profile {
  color: #ffffff;
  text-align: center;
  padding: 10px 0;
}

.app .sidebar .profile .image {
  margin: 0 auto;
}
.app .sidebar .profile .image img {
  border-radius: 50%;
}

.app .sidebar .profile .username {
  display: block;
  margin-top: 1em;
}

.app .sidebar .search {
  margin-top: 1em;
}
.app .sidebar .search input {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
}

.app .sidebar .sidebar-scroll-content {
  display: block;
  height: 100%;
  position: relative;
}

.app .sidebar .sidebar-scroll-content .menu-container {
  padding-bottom: 80px;
}

.app .sidebar .menu {
  margin-top: 20px;
}

.app .sidebar .menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app .sidebar .menu ul li .menu-item {
  color: #ffffff;
  text-decoration: none;
  padding: 0.4em 0.7em;
  display: block;
  box-sizing: border-box;
}

.app .sidebar .menu ul li .menu-item span {
  margin-left: 0.3em;
}

.app .sidebar .menu ul li .menu-item .fa:last-child {
  float: right;
}

.app .sidebar .menu ul li .menu-item:hover {
  background-color: #007be5;
}

.app .sidebar .menu ul li.active-menuitem .menu-item {
  background-color: #2f3138;
  color: #007be5;
}

.app .sidebar .menu ul li.active-menuitem > ul {
  background-color: #2f3138;
}

.app .sidebar .menu ul li > ul {
  display: none;
  padding-left: 20px;
}

.app .main {
  margin-left: 200px;
  /* le calc est fait pour 70px header + 0.75rem du menu topbar + 1em d'espacement pour éviter que ce soit trop collé */
  padding: calc(70px + 0.75rem + 1.25em) 0.4em 1em 0.4em;
}

.app .footer {
  margin-left: 200px;
  height: 40px;
  background-color: #ffffff;
  padding: 0.7em 1.5em 0em 1.5em;
}

.app .footer img {
  vertical-align: middle;
}

.app .footer .footer-text {
  vertical-align: middle;
}

/* Responsive */
@media (max-width: 64em) {
  .app .topbar {
    left: 0;
    text-align: left;
  }

  .app .topbar-menu {
    left: 0;
  }

  .app .topbar .topbar-title {
    padding-top: 0.2em;
    margin-left: 0.4em;
  }

  .app .main {
    margin-left: 0;
  }

  .app .sidebar {
    opacity: 0.97;
    -moz-transition: margin 0.5s;
    -o-transition: margin 0.5s;
    -webkit-transition: margin 0.5s;
    transition: margin 0.5s;
    margin-left: -250px;
    margin-top: 50px;
    width: 250px;
  }

  .app .sidebar.overlay-sidebar {
    margin-left: 0;
  }

  .app .footer {
    margin-left: 0;
  }
}

@media (max-width: 40em) {
  .app .topbar {
    text-align: center;
  }
}

/* ouverture / fermeture de la barre de menu à gauche */
.app .sidebar,
.app .main,
.app .topbar,
.app .topbar-menu,
.app .footer {
  transition: 0.3s ease-in-out;
}

.app .sidebar.close {
  margin-left: -200px;
  transition: 0.3s ease-in-out;
}

@media (max-width: 64em) {
  .app .sidebar {
    margin-left: 0px !important;
  }

  .app .sidebar.close {
    margin-left: -250px !important;
  }

  .app .topbar-history-overlay,
  .app .topbar-history-static {
    left: 0;
  }
}

.app .main.full,
.app .footer.full {
  margin-left: 0px;
  transition: 0.3s ease-in-out;
}

.app .topbar-history.full,
.app .topbar.full,
.app .topbar-history-overlay.full,
.app .topbar-history-static.full,
.app .topbar-menu.full {
  left: 0px;
  transition: 0.3s ease-in-out;
}

#octal_logo_sidebar > g > #octal_logo_icon_text {
  fill: hsl(205, 97%, 45%);
  opacity: 1;
  stroke: hsl(205, 97%, 45%);
  stroke-width: 0.92714322;
  stroke-linecap: butt;
  stroke-linejoin: round;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-opacity: 1;
}

#octal_logo_sidebar:hover {
  cursor: pointer;
}

#octal_logo_sidebar:hover > g > #octal_logo_icon_text {
  fill: hsl(205, 97%, 35%);
  stroke: hsl(205, 97%, 35%);
  opacity: 1;
}

.app .sidebar .ui-menu.sidebar-menu-activity {
  background-color: transparent;
  border: none;
  width: 100%;
}

.app .sidebar .ui-menu.sidebar-menu-activity ul li:hover {
  background-color: #007be5;
}

.app .sidebar .ui-menu.sidebar-menu-activity ul li.active-menuitem > a {
  background: none;
  color: #ffffff;
}

.app .sidebar .ui-menu.sidebar-menu-activity ul li.active-menuitem > a.icon-active-menu {
  color: #e53903;
}

.app .sidebar .menu ul li > a.icon-active-menu {
  vertical-align: middle;
  color: #e53903;
  display: inline-block;
  width: 12px;
  margin-left: 5px;
  padding: 0;
  text-align: center;
}

.app .sidebar .menu ul li > a.icon-active-menu:hover {
  background: none;
}

/* Zone css pour le drag and drop */

/*bleu clair : #C6DEFF | bleu plus fonce : #596b7b*/
.drop-area-fav,
.drop-area-all-fav {
  background-color: #596b7b;
}

.drop-area-all-fav {
  border: 2px dotted #090b0c;
  left: -2px;
  top: -2px;
  position: relative;
}

.ribbon {
  position: absolute;
  background-color: #36b03c91;
  transform: rotate(45deg);
  width: 20rem;
  text-align: left;
  top: 9.7rem;
  right: -10rem;
  font-weight: bold;
  z-index: 11;
}

.ribbon:before {
  content: "";
  border-style: solid;
  border-width: 1.59rem 1.59rem 0 0;
  border-color: transparent #36b03c91 transparent transparent;
  position: absolute;
  left: -1.59rem;
}
