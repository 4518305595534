@keyframes toast-frames-open {
  from {
    opacity: 0;
    transform: translateY(-120%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes toast-frames-close {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.9);
    opacity: 0;
  }
}

.toast-container {
  max-width: 560;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 50;
  pointer-events: none;
  width: 50rem;
}

.toast {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 0;
  transition: all 240ms cubic-bezier(0, 0, 0.2, 1);
  pointer-events: all;
}

.toast[data-state="entering"],
.toast[data-state="entered"] {
  animation: toast-frames-open 240ms cubic-bezier(0.175, 0.885, 0.32, 1.175) both;
}

.toast[data-state="exiting"] {
  animation: toast-frames-close 120ms cubic-bezier(0.4, 0, 1, 1) both;
}

/* .toast[data-state="exited"] {
  display: none;
} */

.toast-alert:before {
  content: "";
  width: 3px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: hsl(217, 71%, 53%);
  background-color: var(--background-intent, hsl(217, 71%, 53%));
}

.toast-alert {
  background-color: white;
  overflow: hidden;
  position: relative;
  display: flex;
  padding: 12px 16px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.toast-alert--icon {
  margin-right: 10px;
  margin-left: 2px;
  height: 14px;
  display: block;
  /* margin-top: 2px; */
}

.toast-alert--header {
  font-weight: 600;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
}

.toast-alert--content {
  font-size: 14px;
  color: hsl(0, 0%, 21%);
}

.toast-alert--delete {
  margin-left: 24px;
  flex-shrink: 0;
  /* margin-bottom: -2px; */
  margin-top: 4px;
}

.toast-alert[data-intent="default"] {
  --background-intent: hsl(0, 0%, 21%);
}

.toast-alert[data-intent="success"] {
  --background-intent: hsl(141, 71%, 48%);
}
.toast-alert[data-intent="danger"] {
  --background-intent: hsl(348, 100%, 61%);
}
.toast-alert[data-intent="warning"] {
  --background-intent: hsl(48, 100%, 67%);
}
.toast-alert[data-intent="info"] {
  --background-intent: hsl(217, 71%, 53%);
}
