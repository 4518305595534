.favoris-wrapper {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.favoris-item {
  color: inherit;
  font-weight: 600;
  letter-spacing: 1px;
}

.gros-menu-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: hsla(205, 80%, 92%, 0.25);
}
