.kanban-header,
.kanban-body {
  overflow-y: hidden;
  color: #393939;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-height: 100%;
}

.kanban-header {
  margin-top: 5px;
}

.kanban-body {
  background-color: hsla(0, 0%, 95%, 1);
}

.kanban-lane {
  max-height: 95%;
  min-height: 95%;
  border-collapse: collapse;
  border: 1px solid hsl(0, 0%, 90%);
}

.kanban-lane .kanban-content {
  padding: 10px;
  overflow-x: hidden;
}

.kanban-title {
  border-bottom: 1px solid hsl(0, 0%, 94%);
  padding: 5px 0;
  font-size: 1.5rem;
}

.kanban-content {
  overflow-y: auto;
}

.kanban-content::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 80%);
  /* border-radius: 10px; */
}

.kanban-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: hsl(0, 0%, 98%);
}

.kanban-content-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsla(0, 0%, 92%, 1);
  border: 1px dashed hsla(0, 0%, 70%, 1);
  border-radius: 10px;
  margin-bottom: 1em;
  letter-spacing: 1px;
}
