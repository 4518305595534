.small-button {
  appearance: none;
  background-color: rgba(10, 10, 10, 0.2);
  color: white;
  border: none;
  border-radius: 290486px;
  cursor: pointer;
  display: inline-block;
  -ms-flex-positive: 0;
  -ms-flex-negative: 0;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 1;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  outline: none;
  position: relative;
  vertical-align: top;
  width: 20px;
}

.tag:not(body) .small-button {
  margin-left: 0.25rem;
  margin-right: -0.375rem;
}
