.avatar {
  position: relative;
}

.avatar:hover .avatar-user {
  opacity: 0.9;
  transition: opacity 0.2s;
}
.avatar .avatar-user {
  opacity: 0;
  transition: opacity 0.2s;
}

.avatar-user {
  position: absolute;
  top: 0px;
  left: 0px;
  font-weight: 600;
  line-height: 32px;
  background: white;
  width: 32px;
  height: 32px;
  border-radius: 9999px;
  text-align: center;
}
