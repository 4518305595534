.table .table-footer-totaux th:first-child {
  border: none;
  background: hsl(0, 0%, 98%);
}

.table .table-footer-totaux tr:first-child th:first-child {
  border-top: 1px solid #dbdbdb;
}

.octal-overlay {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0px 20px 3px rgba(10, 10, 10, 0.2), -1px 0 0 1px rgba(10, 10, 10, 0.1);
}
