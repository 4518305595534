.editor-draft {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;
}

.editor-draft .DraftEditor-root {
  position: relative;
}

.DraftEditor-root,
.DraftEditor-editorContainer,
.public-DraftEditor-content {
  width: inherit;
  height: inherit;
}

.editor-draft .public-DraftEditor-content {
  min-height: 50px;
}

.editor-draft-toolbar-hover {
  left: 50%;
  transform: translate(-50%) scale(0);
  position: absolute;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
  /* z-index: 100; */
  /* box-sizing: border-box; */
}

.editor-draft-toolbar-hover:after,
.editor-draft-toolbar-hover:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.editor-draft-toolbar-hover:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 4px;
  margin-left: -4px;
}
.editor-draft-toolbar-hover:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #ddd;
  border-width: 6px;
  margin-left: -6px;
}
