@keyframes vibrate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.vibrate-bell {
  animation: vibrate 200ms ease-out infinite;
  transform-origin: center;
}

.notification-scroll::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 80%);
}

.notification-scroll::-webkit-scrollbar {
  width: 0.5em;
  background-color: hsl(0, 0%, 98%);
}
